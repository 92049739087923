<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M4 1h24a3 3 0 0 1 3 3v24a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3M3 4v1.645L5.645 3H4a1 1 0 0 0-1 1m0 3.059v3.586L10.645 3H7.059zM12.059 3L3 12.059v3.586L15.645 3zm5 0L3 17.059v3.586L20.645 3zm5 0L3 22.059v3.586L25.645 3zm5 0L3 27.059V28a1 1 0 0 0 1 1h.645L29 4.645V4a1 1 0 0 0-1-1zM29 6.059L6.059 29h3.586L29 9.645zm0 5L11.059 29h3.586L29 14.645zm0 5L16.059 29h3.586L29 19.645zm0 5L21.059 29h3.586L29 24.645zm0 5L26.059 29H28a1 1 0 0 0 1-1z"></path>
  </svg>
</template>

<script lang="ts">
export default {
  name: 'FluentEmojiHighContrastOrangeSquare',
}
</script>
